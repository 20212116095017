import React from "react";
import { useInView } from "react-intersection-observer";
import useTrailHook from "../../utils/useTrailHook";
import ImageFromQuery from "../../components/ImageQueries/MyPicQuery";
import { OuterWrapper, LazyImage, StyledParagraph, StyledH1 } from "./styles";
import { ParagraphList3 } from "./data";

const Quote = () => {
  const [ref, inView] = useInView({
    rootMargin: "-200px",
    triggerOnce: true
  });
  const MyPic = ImageFromQuery();
  const { trail, animated } = useTrailHook(ParagraphList3, inView);
  return (
    <OuterWrapper isAQuote ref={ref}>
      {trail.map((props, i) => (
        <animated.div key={Math.floor(Math.random() * 100000)} style={props}>
          {ParagraphList3[i] === ParagraphList3[0] ? (
            <LazyImage isPic fluid={MyPic} alt="my photo" />
          ) : (
            ""
          )}
          {ParagraphList3[i] === ParagraphList3[1] ? (
            <StyledParagraph isAQuote>
              Not that a person's nature of their faith is just thier faith, but who they are!
            </StyledParagraph>
          ) : (
            ""
          )}
          {ParagraphList3[i] === ParagraphList3[2] ? (
            <StyledH1 isName>Aashish Kandel</StyledH1>
          ) : (
            ""
          )}
          {ParagraphList3[i] === ParagraphList3[3] ? (
            <StyledParagraph isAQuote>not yet working</StyledParagraph>
          ) : (
            ""
          )}
        </animated.div>
      ))}
    </OuterWrapper>
  );
};

export default Quote;
