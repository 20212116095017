import { useStaticQuery, graphql } from "gatsby";

const ImageFromQuery = () => {
  const Image = useStaticQuery(
    graphql`
      query MyPicQuery {
        file(relativePath: { eq: "jpg/myPic.jpg" }) {
          childImageSharp {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
      }
    `
  );

  return Image.file.childImageSharp.fluid;
};

export default ImageFromQuery;
