module.exports = {
  siteTitle: "Aashish Kandel - A Creative Coder", // <title>
  shortSiteTitle: "Aashish Kandel", // <title> ending for posts and pages
  siteDescription: "Hey, I am Aashish Kandel - a machine learning enthusiast. I use machine learning and AI to solve real world problems. Welcome to my site!",
  siteUrl: "https://aashishkandel.com",
  // pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "en",

  /* author */
  authorName: "Aashish Kandel",
  authorTwitterAccount: "akshero",

  /* info */
  headerTitle: "Aashish Kandel",
  headerSubTitle: "Machine learning enthusiast",

  /* manifest.json */
  manifestName: "Aashish Kandel - A Creative Coder",
  manifestShortName: "AK", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "",

  // social
  authorSocialLinks: [
    { name: "github", url: "https://github.com/aks0001" },
    { name: "twitter", url: "https://twitter.com/akshero" },
    { name: "facebook", url: "http://facebook.com/akshero" }
  ]
};
