import styled, { css } from "styled-components";
import Img from "gatsby-image";
import BackgroundImg from "gatsby-background-image";

const OuterWrapper = styled.div`
  padding: ${props =>
    props.isCoding
      ? "24px 0 48px"
      : props.hasBackground
      ? "88px 0"
      : props.isAQuote
      ? "64px 24px"
      : "24px 0"};
  min-height: ${props =>
    props.hasMinHeight
      ? "500px"
      : props.isFirstPage
      ? "600px"
      : props.isCoding || props.isAQuote
      ? "430px"
      : props.hasBackground
      ? "380px"
      : ""};
  box-sizing: ${props =>
    props.isCoding || props.hasBackground || props.isAQuote ? "border-box" : ""};
  background-size: ${props => (props.hasBackground ? "cover" : "")};
  position: ${props => (props.positionRelative ? "relative" : "")};
  @media (max-width: 1115px) {
    padding: ${props => (props.isFirstPage ? "80px 10px 10px" : "")};
  }
  ${props =>
    props.isFirstPage &&
    css`
      display: flex;
      align-items: center;
    `}
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: ${props => (props.paddingZero ? "0 0 36px 0" : "24px 0")};
  align-items: center;
  @media only screen and (max-width: 1024px) {
    text-align: ${props => (props.isFirstPage ? "center" : "")};
  }
  width: 100%;
`;

const FlexChild = styled.div`
  width: ${props => (props.isFirstPage ? "" : props.isLogo ? "350px" : "300px")};
  padding: ${props => (props.isFirstPage ? "40px 0 0 0" : "")};

  @media only screen and (max-width: 768px) {
    width: ${props => (props.isLogo ? "150px" : "")};
    padding: ${props => (props.isFirstPage ? "10px 0 40px 0" : "")};
  }
  @media only screen and (max-width: 560px) {
    width: ${props => (props.isFirstPage ? "350px" : "")};
    padding: ${props => (props.isFirstPage ? "0 0 40px 0" : "")};
  }
  ${props =>
    props.hasBgColor &&
    css`
      background: ${props => props.Color};
      padding: 1.5rem;
      width: 310px;
      border-radius: 15px;
      margin: 13px;
      box-shadow: rgba(0, 0, 0, 0.07) 0px 5px 10px;
    `}
`;

const DescriptionWrapper = styled.div`
  text-align: center;
  padding: 0 4%;
  font-size: 0.9rem;
`;

const FooterWrapper = styled.div`
  height: 72px;
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: #013;
  color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LazyImage = styled(Img)`
  width: ${props =>
    props.isLogo ? "64px" : props.isFirstPage ? "100%" : props.isPic ? "120px" : "100px"};
  display: block;
  margin: auto;
  border-radius: ${props => (props.isPic || props.backgroundColor ? "50%" : "")};
  background-color: ${props => props.backgroundColor};
  padding: ${props => (props.backgroundColor ? "5px" : "")};
`;

const BackgroundImage = styled(BackgroundImg)`
  background-position: ${props => (props.Bottom ? "bottom" : "")};
  background-size: cover;
`;

const StyledH1 = styled.h1`
  text-align: ${props => (props.formHeading ? "" : "center")};
  margin: ${props =>
    props.isTop
      ? props.formHeading
        ? "5vh auto 0 5px"
        : "0 auto 16px"
      : props.isLogoHeading
      ? "10px auto 24px"
      : props.isName
      ? "24px auto 0"
      : "10px auto"};
  font-size: ${props => (props.isTop ? "32px" : props.isLogoHeading ? "16px" : "24px")};
  font-weight: ${props => (props.isTop ? "800" : "600")};
  color: ${props => (props.isTop || props.isName ? "#709425" : "")};
`;

const StyledH2 = styled.h2`
  color: ${props => (props.biggest ? "#709425" : "")};
  font-family: ${props => (props.isCursive ? "cursive" : "")};
  font-size: ${props => (props.biggest ? "48px" : props.smallest ? "18px" : "32px")};
  margin: ${props => (props.biggest ? "0 0 8px" : props.smallest ? "0 0 48px" : "0 0 24px")};
  font-weight: ${props => (props.smallest ? "400" : "")};
`;

const StyledButton = styled.button`
  font-size: ${props => (props.isWide ? "1.2rem" : "1.1rem")};
  padding: ${props => (props.isWide ? "0.5em 2.5em" : "12px")};
  background-color: #99c244;
  border-radius: ${props => (props.isWide ? "5px" : "20px")};
  border: none;
  outline: none;
  cursor: pointer;
  margin-bottom: 16px;
`;

const StyledParagraph = styled.div`
  font-size: 0.9rem;
  text-align: center;
  margin: ${props => (props.isAQuote ? "8px auto" : "")};
`;

const CodeBlock = styled.div`
  background: rgb(30, 30, 30);
  color: white;
  overflow: auto;
  color: rgb(212, 212, 212);
  font-size: 13px;
  text-shadow: none;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
  padding: 0 15px 0 4em;
  border-radius: 12px;
  hyphens: none;
  margin: 0.5em 0px;
  color: "#d4d4d4";
`;

export {
  OuterWrapper,
  FlexContainer,
  FlexChild,
  DescriptionWrapper,
  LazyImage,
  BackgroundImage,
  StyledH1,
  StyledH2,
  StyledButton,
  StyledParagraph,
  FooterWrapper,
  CodeBlock
};
