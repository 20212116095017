import React from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import { StyledH1, StyledButton } from "../Home/styles";

const Contact = () => {
  const [state, setState] = React.useState({});

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }
  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state
      })
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error));
  };
  return (
    <div>
      <StyledH1 isTop formHeading>
        Send an anoonymous message
      </StyledH1>
      <StyledForm
        name="contact"
        method="post"
        action="/success"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
        <p>
          <label>
            Name:
            <br />
            <input type="text" name="name" onChange={handleChange} />
          </label>
        </p>
        <p>
          <label>
            <span>*</span>&nbsp;Email:
            <br />
            <input type="email" name="email" onChange={handleChange} required />
          </label>
        </p>
        <p>
          <label>
            <span>*</span>&nbsp;Message:
            <br />
            <textarea name="message" onChange={handleChange} required />
          </label>
        </p>
        <p>
          <StyledButton isWide type="submit">
            Submit
          </StyledButton>
        </p>
      </StyledForm>
    </div>
  );
};

export default Contact;

const StyledForm = styled.form`
  padding: 5px 5px 20px;

  p {
    margin-bottom: 1rem;
    font-size: 14px;
    span {
      color: red;
    }
  }
  input {
    width: 350px;
    height: 36px;
    border: 1px solid #777;
    border-radius: 5px;
    outline: none;
    font-size: 1rem;
    padding: 0.3rem;
  }
  textarea {
    width: 700px;
    height: 120px;
    border: 1px solid #999;
    border-radius: 9px;
    outline: none;
    font-size: 1rem;
    padding: 0.3rem;
  }
  @media only screen and (max-width: 768px) {
    textarea {
      width: 90vw;
    }
    input {
      width: 60vw;
    }
  }
`;
