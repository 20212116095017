import styled from "styled-components";
import React from "react";

const StyledBurger = styled.button`
  position: fixed;
  right: 20px;
  bottom: 30px;
  background: #709425;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 1024px) {
    display: none;
  }
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2.3rem;
    height: 0.3rem;
    margin: 4px 1px;
    background: white;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const Burger = ({ open, handleClick }) => {
  return (
    <StyledBurger open={open} onClick={handleClick} aria-label="menu button">
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

export default Burger;
