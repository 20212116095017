import React from "react";
import {
  Animations,
  SimpleProjects,
  MachineLearningProjects,
  ProjectWrapper,
  StraightLine,
  CurvedLine
} from "./styles";
import { Link } from "gatsby";

import TensorflowIcon from "../../images/svg-icons/tensorflow.svg";
import { GiFilmProjector, GiChart } from "react-icons/gi";
import { GrReactjs, GrGatsbyjs, GrGraphQl } from "react-icons/gr";
import { IoLogoJavascript } from "react-icons/io";
import { FaHtml5, FaCss3Alt, FaPython, FaProjectDiagram, FaServer } from "react-icons/fa";
import { useTrailOnlyOnce } from "../../utils/useTrailHook";

const Projects = ({ theme }) => {
  const { trail, animated } = useTrailOnlyOnce([1, 2, 3]);
  const description = [
    "Here are some collections of cool canvas animtions. These are some cool little animtion projects that are created for fun using javascript.",
    "These are some fun web apps created with react and javascript. Some of them are really fun while most of them are really useful.",
    "Here's a collection of real world machine learning projects. These are projects that uses cutting edge machine learning and deep learning technologies."
  ];

  return (
    <div style={{ position: "relative", overflowX: "hidden" }}>
      <CurvedLine />
      <ProjectWrapper background={theme.color.neutral.white}>
        {trail.map((props, i) => (
          <animated.div key={Math.floor(Math.random() * 100000)} style={props}>
            {i == 0 && (
              <Animations>
                <h1>Simple Animations</h1>
                <p>{description[0]}</p>
                <FaHtml5 />
                <FaCss3Alt />
                <IoLogoJavascript />
                <Link to="/projects/animations">
                  <button>Explore</button>
                </Link>
                <GiChart />
              </Animations>
            )}
            {i == 1 && (
              <SimpleProjects>
                <h1>Fun Projects</h1>
                <p>{description[1]}</p>
                <GrReactjs />
                <GrGatsbyjs />
                <GrGraphQl />
                <Link to="/projects/funprojects">
                  <button>Explore</button>
                </Link>
                <GiFilmProjector />
              </SimpleProjects>
            )}
            {i == 2 && (
              <MachineLearningProjects>
                <h1>Machine learning Projects</h1>
                <p>{description[2]}</p>
                <FaPython />
                <TensorflowIcon />
                <FaServer />
                <Link to="/projects/machinelearning">
                  <button>Explore</button>
                </Link>
                <FaProjectDiagram />
              </MachineLearningProjects>
            )}
          </animated.div>
        ))}
      </ProjectWrapper>
      <StraightLine />
    </div>
  );
};

export default Projects;
