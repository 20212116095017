import React from "react";
import { ThemeContext } from "../layouts";
import Article from "../components/Article/ArticleAside";
import Contact from "../components/Contact";
import { StyledH1, FlexContainer } from "../components/Home/styles";
import { FaFacebook, FaInstagram, FaGithub, FaLinkedinIn } from "react-icons/fa";
import Seo from "../components/Seo";

const ContactPage = () => {
  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article
            theme={theme}
            asideContent={
              <>
                <StyledH1 isTop formHeading>
                  Send me a direct message
                </StyledH1>
                <FlexContainer className="container">
                  <div>
                    <a target="_blank" href="https://www.facebook.com/akshero">
                      <FaFacebook className="fa" />
                    </a>
                    <a target="_blank" href="https://www.facebook.com/akshero">
                      <p>/akshero</p>
                    </a>
                  </div>
                  <div>
                    <a target="_blank" href="https://www.instagram.com/asis_kdl">
                      <FaInstagram className="fa" />
                    </a>
                    <a target="_blank" href="https://www.instagram.com/asis_kdl">
                      <p>/asis_kdl</p>
                    </a>
                  </div>
                  <div>
                    <a target="_blank" href="https://github.com/aks0001">
                      <FaGithub className="fa" />
                    </a>
                    <a target="_blank" href="https://github.com/aks0001">
                      <p>/aks0001</p>
                    </a>
                  </div>
                  <div>
                    <a target="_blank" href="https://www.linkedin.com/in/aashish-kandel-2201b21a7/">
                      <FaLinkedinIn className="fa" />
                    </a>
                    <a target="_blank" href="https://www.linkedin.com/in/aashish-kandel-2201b21a7/">
                      <p>/ashish-kandel</p>
                    </a>
                  </div>
                </FlexContainer>
                <style jsx>
                  {`
                    .container {
                      width: 375px;
                    }
                    .container p {
                      text-align: center;
                    }
                    .container a {
                      color: ${theme.background.color.brand};
                    }

                    .fa {
                      font-size: 6rem;
                      padding: 10px;
                      width: 180px;
                      cursor: pointer;
                      transition-duration: 1s;
                      color: ${theme.background.color.brand};
                    }
                    .fa:hover {
                      font-size: 6.7rem;
                    }
                    @media only screen and (max-width: 1145px) {
                      .container {
                        width: 100%;
                      }
                    }
                  `}
                </style>
              </>
            }
          >
            <Contact />
          </Article>
        )}
      </ThemeContext.Consumer>

      <Seo />
    </React.Fragment>
  );
};

export default ContactPage;
