import React from "react";
import { useTrailOnlyOnce } from "../../utils/useTrailHook";
import { ParagraphList1 } from "./data";
import {
  OuterWrapper,
  FlexContainer,
  FlexChild,
  StyledH2,
  StyledButton,
  CodeBlock
} from "./styles";

const FirstPage = () => {
  const { trail, animated } = useTrailOnlyOnce(ParagraphList1);
  const colorize = red => {
    return { color: red };
  };
  return (
    <OuterWrapper isFirstPage>
      <FlexContainer isFirstPage>
        <FlexChild isFirstPage>
          {trail.map((props, i) => (
            <animated.div key={Math.floor(Math.random() * 100000)} style={props}>
              {i == 0 && <StyledH2 biggest>{ParagraphList1[i]}</StyledH2>}
              {i == 1 && <StyledH2 isCursive>{ParagraphList1[i]}</StyledH2>}
              {i == 2 && <StyledH2 smallest>{ParagraphList1[i]}</StyledH2>}
              {i == 3 && <StyledButton>{ParagraphList1[i]}</StyledButton>}
            </animated.div>
          ))}
        </FlexChild>

        <FlexChild isFirstPage>
          {trail.map((props, i) => (
            <animated.div key={Math.floor(Math.random() * 100000)} style={props}>
              {i == 0 && (
                <CodeBlock>
                  <br />
                  <span style={colorize("#c586c0")}>let</span> life;
                  <br />
                  <br />
                  <span style={colorize("#dcdcaa")}>predictLife</span> ={" "}
                  <span style={colorize("#c586c0")}>async</span> {"("}
                  {")"}={">"}
                  {"{"}
                  <br />
                  <div style={{ paddingLeft: "1em" }}>
                    <span style={colorize("#4ec9b0")}>console</span>
                    <span style={colorize("#dcdcaa")}>.log</span>
                    <span>{"("}</span>
                    <span style={colorize("#ce9178")}>'loading lifeModel...'</span>
                    <span>{")"}</span>;
                    <br />
                    <br />
                    <span style={colorize("#6a9955")}>//load the model</span>
                    <br />
                    life = <span style={colorize("#c586c0")}>await </span>
                    lifeModel
                    <span style={colorize("#dcdcaa")}>.load</span>
                    <span>{"("}</span>
                    <span>{")"}</span>;
                    <br />
                    <span style={colorize("#4ec9b0")}>console</span>
                    <span style={colorize("#dcdcaa")}>.log</span>
                    <span>{"("}</span>
                    <span style={colorize("#ce9178")}>'lifeModel loading successful'</span>
                    <span>{")"}</span>;
                    <br />
                    <br />
                    <span style={colorize("#6a9955")}>//make prediction via model</span>
                    <br />
                    <span style={colorize("#c586c0")}>const </span>lifeData =
                    <span style={colorize("#d16969")}> document</span>
                    <span style={colorize("#dcdcaa")}>.getElementById</span>
                    <span>{"("}</span>
                    <span style={colorize("#ce9178")}>'life-data'</span>
                    <span>{")"}</span>;
                    <br />
                    <span style={colorize("#c586c0")}>const </span>result =
                    <span style={colorize("#c586c0")}> await </span>lifeModel.
                    <span style={colorize("#dcdcaa")}>classify</span>
                    <span>{"("}</span>
                    lifeData
                    <span>{")"}</span>;
                    <br />
                    <span style={colorize("#4ec9b0")}>console</span>
                    <span style={colorize("#dcdcaa")}>.log</span>
                    <span>{"("}</span>
                    result
                    <span>{")"}</span>;
                    <br />
                  </div>
                  <span>{"}"}</span>
                  <br />
                  <br />
                  <span style={colorize("#dcdcaa")}>predictLife</span>
                  <span>{"("}</span>
                  <span>{")"}</span>;
                  <br />
                  <br />
                </CodeBlock>
              )}
            </animated.div>
          ))}
        </FlexChild>
      </FlexContainer>
    </OuterWrapper>
  );
};

export default FirstPage;
