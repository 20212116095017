import React from "react";
import { FaJsSquare } from "react-icons/fa/";
import { FaHtml5 } from "react-icons/fa/";
import { FaCss3 } from "react-icons/fa/";

const Card = props => {
  return (
    <div className="card_wrapper">
      <div
        style={{
          order: props.order
        }}
        className="windowheight"
      >
        <iframe src={props.live_demo} loading="lazy" className="iframe" />
      </div>
      <div className="textheight">
        <h3 style={{ order: "1", textAlign: "center", marginTop: "19px", fontFamily: "cursive" }}>
          {props.heading}
        </h3>
        <p>{props.description1}</p>
        <p>{props.description2}</p>
        <p>{props.description3}</p>
        <div className="font_awesome">
          <FaHtml5 />
          <FaCss3 />
          <FaJsSquare />
        </div>
        <div className="rect_style">
          <a href={props.live_demo} target="_blank">
            Live Demo
          </a>
          <a href={props.view_source} target="_blank">
            View Source
          </a>
        </div>
      </div>

      <style>{`
            .card_wrapper{
                display: flex;
                justify-content: space-around;
                height: 80vh;
              }
              .textheight{
                width:37%;
                order:0;
                border-radius:2%;
              }
              .windowheight{
                height:80%;
                width:57%;
                order:1;
                overflow:hidden;
                border-radius:20px;
              }
              .iframe{
                height:104%;
                width:103%;
                border:none;
              }
              .rect_style a{
                text-decoration: none;
              }
              @media only screen and (max-width: 1113px ){
                .textheight p{
                  font-size: 10px;
                  text-align: left;
                  margin-buttom: 15px;
                }
              }
              @media only screen and (max-width: 900px ){
                .card_wrapper{
                  display: contents;
                }
                .windowheight{
                  margin-left: 4%;
                  height: 351px;
                  width: 95%;
                }
                .textheight{
                  height: 400px;
                  width: 100%;
                }
              }
              
              .textheight h3{
                background: -webkit-linear-gradient(#fc466b, #3f5efb);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 33px;
              }
              .textheight p{
                margin-right: 30px;;
                margin-left: 30px;;
                max-width: none;
                padding: 5px;
                text-align: justify;
                font-size: 17px;
                font-family: cursive;
              }
              .rect_style{
                display: flex;
                justify-content: space-around;
                width: 87%;
                height: 45px;
                margin-top: 1.7rem;
                margin-left: 1.7rem;
                font-size: 1.3rem;
                padding: 5px;
                border-radius: 10px;
                border-width: 1.7px;
                border-style: solid;
                border-color: rgb(167, 123, 233);
                border-image: initial;
              }
              .rect_style a{
                cursor: pointer;
                color: blue;
              }

              .rect_style a:hover{
                transform: scale(1.03);
                color: red;
              }
              
              /*****font awesome****/
              .font_awesome{
                  font-size: 32px;
                  color: rgb(68, 38, 187);
                display: flex;
                width: 70%;
                justify-content: space-around;
                margin-left: 20px;
                margin-top: 9px;
              }
              @media only screen and (max-width: 533px ){
                .windowheight{
                  height: 317px;
                  width: 91%;
                  margin-buttom: 13%;
                }
                .textheight p, h3{
                  font-family: 'Open Sans', Arial;
                }
                .textheight{
                  margin-bottom: 9vh;
                }
                .textheight h3{
                  font-size:1.5rem;
                }
                .iframe{
                  height:100%;
                  width:100%;
                }
              }
  
            `}</style>
    </div>
  );
};

export default Card;
