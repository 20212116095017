import React from "react";
import { useInView } from "react-intersection-observer";
import useTrailHook from "../../utils/useTrailHook";
import BackgroundImageQuery from "../../components/ImageQueries/BackgroundImageQuery";
import { ParagraphList2 } from "./data";
import { OuterWrapper, StyledH1, StyledParagraph, BackgroundImage } from "./styles";

const OtherSkills = () => {
  const [ref, inView] = useInView({
    rootMargin: "-200px",
    triggerOnce: true
  });
  const BackgroundImg = BackgroundImageQuery();
  const { trail, animated } = useTrailHook(ParagraphList2, inView);
  return (
    <BackgroundImage Bottom fluid={BackgroundImg}>
      <OuterWrapper hasBackground ref={ref}>
        <StyledH1 isTop>Other Skills</StyledH1>
        {trail.map((props, i) => (
          <animated.div key={Math.floor(Math.random() * 100000)} style={props}>
            <StyledParagraph>{ParagraphList2[i]}</StyledParagraph>
          </animated.div>
        ))}
      </OuterWrapper>
    </BackgroundImage>
  );
};

export default OtherSkills;
