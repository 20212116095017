import styled from "styled-components";
import CurvLine from "../../images/svg-icons/curved-line.svg";
import StrLine from "../../images/svg-icons/line.svg";

export const ProjectWrapper = styled.div`
  padding: 46px 0 0 0;
  background: ${props => props.background};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  @media (max-width: 1023px) {
    padding-top: 60px;
  }
`;

export const FlexElement = styled.div`
  height: 300px;
  width: 320px;
  background: #2c303a;
  margin: 1.7rem 0.4rem;
  padding: 4.1rem 1rem 1rem;
  color: #fff;
  border-radius: 5%;
  box-shadow: 13px 13px 0 0 #709425;
  transition: all 0.5s ease-in-out;
  position: relative;
  h1 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  p {
    margin-top: 0.8rem;
    font-size: 0.9rem;
    color: #c7c9d3;
  }
  button {
    background: #444857;
    padding: 0.5rem 1.2rem;
    border-radius: 5px;
    border: none;
    margin: 0.6rem 0 0.3rem;
    color: #fff;
    font-size: 1.1rem;
    cursor: pointer;
    outline: none;
    transition: all 0.5s ease-in-out;
    &:hover {
      transform: scale(1.07);
    }
  }
  svg {
    color: #709425;
    font-size: 2rem;
    margin: 0.9rem 2rem 0;
  }
  svg:last-child {
    border-radius: 15%;
    border: 9px solid #000;
    position: absolute;
    font-size: 4.3rem;
    color: #709425;
    left: 30px;
    top: -2rem;
    background: #000;
    margin: auto;
  }
  /* flex element wrap for for ipad pro */
  @media (max-width: 1024px) {
    margin: 1.9rem 0.9rem;
  }
`;

export const Animations = styled(FlexElement)``;
export const SimpleProjects = styled(FlexElement)``;
export const MachineLearningProjects = styled(FlexElement)``;

export const CurvedLine = styled(CurvLine)`
  position: absolute;
  height: 340px;
  right: -30px;
  @media (max-width: 1023px) {
    top: 7px;
    right: -50px;
  }
`;
export const StraightLine = styled(StrLine)`
  height: 80px;
  margin: -16px 0 0 -76px;
  @media (max-width: 500px) {
    width: 94vw;
  }
`;
