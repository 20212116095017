import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Item = props => {
  const { item: { label, to, icon: Icon } = {}, theme } = props;

  return (
    <ListItem theme={theme}>
      <Link to={to} data-slug={to}>
        {Icon && <Icon />} {label}
      </Link>
    </ListItem>
  );
};

export default Item;

const ListItem = styled.li`
  & a {
    color: ${props => props.theme.color.neutral.black};
    font-size: 1rem;
    font-weight: 600;
    padding: 10px;
    & svg {
      color: #999;
    }
  }
  cursor: pointer;
  z-index: 3;
  display: flex;
  align-items: center;
  background: transparent;
  border-radius: 5px;
  transition: all 0.5s ease 0s;
  :hover {
    background-color: rgba(102, 217, 239, 0.4);
    & svg {
      color: ${props => props.theme.color.brand.primary};
    }
  }
  @media screen and (max-width: 1023px) {
    background-color: #709425;
    margin-top: 5px;
    & svg {
      display: none;
    }
  }
`;
