import "typeface-open-sans";
import FontFaceObserver from "fontfaceobserver";
import PropTypes from "prop-types";
import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Header from "../components/Header";

export const ThemeContext = React.createContext(null);
export const FontLoadedContext = React.createContext(false);

import themeObjectFromYaml from "../theme/theme.yaml";
import darkThemeObjectFromYaml from "../theme/darkTheme.yaml";

class Layout extends React.Component {
  constructor() {
    super();

    this.state = {
      font400loaded: false,
      font600loaded: false,
      headerMinimized: false,
      isDarkOn:
        typeof window !== "undefined" && "isDarkOn" in localStorage
          ? JSON.parse(localStorage.getItem("isDarkOn"))
          : (typeof window !== "undefined" &&
              window.matchMedia("(prefers-color-scheme: dark)").matches) ||
            false,
      theme: themeObjectFromYaml
    };

    if (typeof window !== `undefined`) {
      this.loadFont("font400", "Open Sans", 400);
      this.loadFont("font600", "Open Sans", 600);
    }
  }

  timeouts = {};

  componentDidMount() {
    this.setState({
      theme: this.state.isDarkOn ? darkThemeObjectFromYaml : themeObjectFromYaml
    });
  }

  componentDidUpdate() {
    localStorage.setItem("isDarkOn", JSON.stringify(this.state.isDarkOn));
  }

  setIsDarkOn = props => {
    this.setState({
      isDarkOn: props,
      theme: props ? darkThemeObjectFromYaml : themeObjectFromYaml
    });
  };

  loadFont = (name, family, weight) => {
    const font = new FontFaceObserver(family, {
      weight: weight
    });

    font.load(null, 10000).then(
      () => {
        this.setState({ [`${name}loaded`]: true });
      },
      () => {
        console.log(`${name} is not available`);
      }
    );
  };

  render() {
    return (
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            pages: allMarkdownRemark(
              filter: { fileAbsolutePath: { regex: "//pages//" }, fields: { prefix: { regex: "/^\\d+$/" } } }
              sort: { fields: [fields___prefix], order: ASC }
            ) {
              edges {
                node {
                  fields {
                    slug
                    prefix
                  }
                  frontmatter {
                    title
                    menuTitle
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const { children } = this.props;
          const {
            pages: { edges: pages }
          } = data;

          return (
            <ThemeContext.Provider value={this.state.theme}>
              <FontLoadedContext.Provider value={this.state.font400loaded}>
                <React.Fragment>
                  <Header
                    path={this.props.location.pathname}
                    pages={pages}
                    theme={this.state.theme}
                    darkModeProps={{
                      isDarkOn: this.state.theme.isDarkOn,
                      setIsDarkOn: this.setIsDarkOn
                    }}
                  />
                  <main>{children}</main>

                  {/* --- STYLES --- */}
                  <style jsx global>{`
                    html {
                      box-sizing: border-box;
                      background-color: ${this.state.theme.background.color.primary};
                      color: ${this.state.theme.background.color.text};
                    }
                    *,
                    *:after,
                    *:before {
                      box-sizing: inherit;
                      margin: 0;
                      padding: 0;
                    }
                    body {
                      font-family: ${this.state.font400loaded
                        ? "'Open Sans', sans-serif;"
                        : "Arial, sans-serif;"};
                    }
                    h1,
                    h2,
                    h3 {
                      font-weight: ${this.state.font600loaded ? 600 : 400};
                      line-height: 1.1;
                      letter-spacing: -0.03em;
                      margin: 0;
                    }
                    h1 {
                      letter-spacing: -0.04em;
                    }
                    p {
                      margin: 0;
                    }
                    strong {
                      font-weight: ${this.state.font600loaded ? 600 : 400};
                    }
                    a {
                      text-decoration: none;
                      color: #666;
                    }
                    main {
                      width: auto;
                      display: block;
                    }
                  `}</style>
                </React.Fragment>
              </FontLoadedContext.Provider>
            </ThemeContext.Provider>
          );
        }}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default Layout;
