import React from "react";
import { graphql } from "gatsby";
import { ThemeContext } from "../../layouts";
import Seo from "../../components/Seo";
import Project from "../../components/Projects/CardWrapper";

export default class Projects extends React.Component {
  render() {
    const {
      data: { projects }
    } = this.props;
    return (
      <React.Fragment>
        <ThemeContext.Consumer>
          {theme => <Project projects={projects} theme={theme} />}
        </ThemeContext.Consumer>
        <Seo />
      </React.Fragment>
    );
  }
}

export const ProjectQuery = graphql`
  query funProjectQuery {
    projects: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//content/projects/funprojects/" } }
      sort: { fields: fields___slug, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            heading
            description1
            description2
            description3
            live_demo
            view_source
          }
        }
      }
    }
  }
`;
