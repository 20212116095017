import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import { FaHome } from "react-icons/fa/";
import { FaEnvelope } from "react-icons/fa/";
import { FaTag } from "react-icons/fa/";
import { FaCogs } from "react-icons/fa/";
import { FaRss } from "react-icons/fa/";
import Item from "./Item";
import Burger from "./Burger";
import DarkThemeToggle from "../DarkThemeToggle/DarkThemeToggle";

const Menu = props => {
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isDarkOn, setIsDarkOn } = props.darkModeProps;

  const handleClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);

  const pages = props.pages.map(page => ({
    to: page.node.fields.slug,
    label: page.node.frontmatter.menuTitle
      ? page.node.frontmatter.menuTitle
      : page.node.frontmatter.title
  }));
  const items = [
    { to: "/", label: "Home", icon: FaHome },
    { to: "/blog/", label: "Blogs", icon: FaRss },
    { to: "/category/", label: "Categories", icon: FaTag },
    { to: "/projects/", label: "Projects", icon: FaCogs },
    ...pages,
    { to: "/contact/", label: "Contact", icon: FaEnvelope }
  ];
  return (
    <nav rel="js-menu">
      <NavLinks isMenuOpen={isMenuOpen}>
        {items.map(item => (
          <Item item={item} key={item.label} theme={props.theme} />
        ))}
      </NavLinks>
      <div ref={ref}>
        <Burger open={isMenuOpen} handleClick={handleClick} />
      </div>
      <DarkThemeToggle isDarkOn={isDarkOn} themeToggle={setIsDarkOn} isMenuOpen={isMenuOpen} />
    </nav>
  );
};

export default Menu;

const NavLinks = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media screen and (max-width: 1023px) {
    flex-direction: column-reverse;
    position: fixed;
    right: 5px;
    bottom: 163px;
    z-index: 1;
    outline: none;
    cursor: pointer;
    display: ${props => (props.isMenuOpen ? "" : "none")};
  }
`;
