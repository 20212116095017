import React from "react";
import Card from "./Card";

export default function Project(props) {
  const { projects, theme } = props;

  return (
    <>
      {projects.edges.map((item, i) => (
        <Card
          key={i}
          heading={item.node.frontmatter.heading}
          description1={item.node.frontmatter.description1}
          description2={item.node.frontmatter.description2}
          description3={item.node.frontmatter.description3}
          live_demo={item.node.frontmatter.live_demo}
          view_source={item.node.frontmatter.view_source}
          order={i % 2 == 0 ? 1 : -1}
          theme={theme}
        />
      ))}
    </>
  );
}
