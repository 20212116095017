import { useStaticQuery, graphql } from "gatsby";

const ImagesFromQuery = () => {
  const Images = useStaticQuery(
    graphql`
      query ImagesQuery2 {
        allFile(
          filter: { relativeDirectory: { eq: "programming-icons" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              childImageSharp {
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    `
  );
  const ImagesArray = [];

  Images.allFile.edges.map(item => {
    ImagesArray.push(item.node.childImageSharp.fluid);
  });

  return ImagesArray;
};

export default ImagesFromQuery;
