import React from "react";
import PropTypes from "prop-types";

const ArticleAside = props => {
  const { children, asideContent, theme } = props;

  return (
    <div className="articleWrapper">
      <article className="article">{children}</article>
      <aside className="aside">{asideContent}</aside>

      {/* --- STYLES --- */}
      <style jsx>{`
        .articleWrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
        }
        article {
          padding: 10px;
          flex-grow: 1;
          width: 750px;
          display: flex;
          justify-content: center;
        }
        aside {
          padding: 10px;
          min-width: 200px;
          flex-grow: 2;
        }
      `}</style>
    </div>
  );
};

ArticleAside.propTypes = {
  children: PropTypes.node.isRequired,
  asideContent: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default ArticleAside;
