import { useTrail, animated } from "react-spring";

const useTrailHook = (props, onScreen = true) => {
  const trail = useTrail(props.length, {
    config: { mass: 1, tension: 170, friction: 23 },
    opacity: onScreen ? 1 : 0,
    transform: onScreen ? "translate(0px,30px)" : "translate(-10px, 150px",
    from: { opacity: 0, transform: "translate(-10px, 150px" }
  });
  // //update spring with new props
  // set({
  //   opacity: onScreen ? 1 : 0,
  //   transform: onScreen ? "translate(0px,30px)" : "translate(-10px, 150px)"
  // });
  // //stop the animation
  // stop();
  return { trail, animated };
};

const useTrailOnlyOnce = props => {
  const trail = useTrail(props.length, {
    config: { mass: 1, tension: 170, friction: 23 },
    opacity: 1,
    transform: "translate(0px,0px)",
    from: { opacity: 0, transform: "translate(-10px, 60px" }
  });
  return { trail, animated };
};

export default useTrailHook;
export { useTrailOnlyOnce };
