import React from "react";
import FirstPage from "./FirstPage";
import WhatIDo from "./WhatIDo";
import CodingSkills from "./CodingSkills";
import OtherSkills from "./OtherSkills";
import Quote from "./Quote";
import Footer from "./Footer";

const Home = ({ theme }) => {
  return (
    <React.Fragment>
      <FirstPage />
      <WhatIDo theme={theme} />
      <CodingSkills />
      <OtherSkills />
      <Quote />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
