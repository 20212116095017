const ParagraphList1 = [
  "Hey",
  "I am Aashish",
  "`You know what seems odd to me? Number%2!=0`",
  "Download Resume"
];
const ParagraphList2 = [
  "Mathematics, Statistics, Algorithms, Data Structure, penetration and explotation, linux, etc",
  "Comm media: Nepali, Hindi, English, Spanish,"
];
const ParagraphList3 = [
  "myPic",
  "Not that a person's nature of their faith is just thier faith, but who they are!",
  "Aashish Kandel",
  "not yet working"
];

const HeadingList1 = ["Research and Learning", "Coding and Algorithms", "Machine learning and AI"];
const HeadingList2 = ["react", "gatsby", "graphql", "javascript", "python", "tensorflow"];

const DescriptionList = [
  "I find it intriguing to do self learning, and researching about topics I like.",
  "Writing computer programs to solve real world problems is my main motto.",
  "One can't just write every sets of rules, I believe we should let computer think!"
];

export {
  HeadingList1,
  DescriptionList,
  HeadingList2,
  ParagraphList1,
  ParagraphList2,
  ParagraphList3
};
