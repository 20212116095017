import React from "react";
import { FiSun, FiMoon } from "react-icons/fi";

const DarkThemeToggle = props => {
  const themeToggleStyle = {
    fontSize: "3.7rem",
    color: "#fff",
    backgroundColor: "#709425",
    borderRadius: "50%",
    padding: "0.7rem"
  };
  return (
    <React.Fragment>
      <button
        onClick={() => props.themeToggle(!props.isDarkOn)}
        aria-label="dark/white mode toggle"
      >
        {props.isDarkOn ? <FiSun style={themeToggleStyle} /> : <FiMoon style={themeToggleStyle} />}
      </button>
      <style jsx>
        {`
          button {
            border: none;
            height: 3.7rem;
            width: 3.7rem;
            position: fixed;
            right: 25px;
            bottom: 30px;
            z-index: 1;
            outline: none;
            cursor: pointer;
            background: transparent;
          }
          @media only screen and (max-width: 1024px) {
            button {
              bottom: 98px;
              display: ${props.isMenuOpen ? "" : "none"};
            }
          }
        `}
      </style>
    </React.Fragment>
  );
};

export default DarkThemeToggle;
