import React from "react";
import PropTypes from "prop-types";
import {DiscussionEmbed} from "disqus-react";

const Comments = props => {
  const {theme, disqusConfig} = props;

  return (
    <React.Fragment>
      <div className="comments">
        <DiscussionEmbed {...disqusConfig}/>
      </div>

      {/* --- STYLES --- */}
      <style jsx>{`
        .comments {
          margin: 0 -8px ${theme.space.default};
        }
      `}</style>
    </React.Fragment>
  );
};

Comments.propTypes = {
  theme: PropTypes.object.isRequired,
  disqusConfig: PropTypes.object.isRequired
};

export default Comments;
