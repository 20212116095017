import React from "react";
import { useInView } from "react-intersection-observer";
import useTrailHook from "../../utils/useTrailHook";
import ImagesFromQuery from "../../components/ImageQueries/ImageQuery2";
import { HeadingList2 } from "./data";
import { OuterWrapper, StyledH1, FlexContainer, FlexChild, LazyImage } from "./styles";

const CodingSkills = () => {
  const [ref, inView] = useInView({
    rootMargin: "-200px",
    triggerOnce: true
  });
  const ImagesArray = ImagesFromQuery();
  const { trail, animated } = useTrailHook(ImagesArray, inView);

  return (
    <OuterWrapper isCoding ref={ref}>
      <StyledH1 isTop>Coding Skills</StyledH1>
      <FlexContainer isCoding>
        {trail.map((props, i) => (
          <animated.div key={Math.floor(Math.random() * 100000)} style={props}>
            <FlexChild isLogo>
              <LazyImage isLogo fluid={ImagesArray[i]} alt={`${i} image`} />
              <StyledH1 isLogoHeading>{HeadingList2[i]}</StyledH1>
            </FlexChild>
          </animated.div>
        ))}
      </FlexContainer>
    </OuterWrapper>
  );
};

export default CodingSkills;
