import React from "react";
import Home from "../components/Home";
import Seo from "../components/Seo";
import { ThemeContext } from "../layouts";

class IndexPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <ThemeContext.Consumer>{theme => <Home theme={theme} />}</ThemeContext.Consumer>

        <Seo />
      </React.Fragment>
    );
  }
}

export default IndexPage;
