import React from "react";
import Loadable from "react-loadable";
import { useInView } from "react-intersection-observer";
import useTrailHook from "../../utils/useTrailHook";
import { HeadingList1, DescriptionList } from "./data";
import { FlexContainer, FlexChild, DescriptionWrapper, StyledH1, OuterWrapper } from "./styles";

const Research = Loadable({
  loader: () => import("../../utils/namedExport/ResearchSvg"),
  loading() {
    return <div>Loading...</div>;
  }
});
const Coding = Loadable({
  loader: () => import("../../utils/namedExport/CodingSvg"),
  loading() {
    return <div>Loading...</div>;
  }
});
const ML = Loadable({
  loader: () => import("../../utils/namedExport/MLSvg"),
  loading() {
    return <div>Loading...</div>;
  }
});

const SVGArray = [Research, Coding, ML];

const WhatIDo = ({ theme }) => {
  const [ref, inView] = useInView({
    rootMargin: "-200px",
    triggerOnce: true
  });
  const { trail, animated } = useTrailHook(SVGArray, inView);

  return (
    <OuterWrapper ref={ref} positionRelative>
      <StyledH1 isTop>What I Do?</StyledH1>
      <FlexContainer paddingZero>
        {trail.map((props, i) => (
          <animated.div key={Math.floor(Math.random() * 100000)} style={props}>
            <FlexChild hasBgColor Color={theme.boxColor}>
              {i == 0 && <Research />}
              {i == 1 && <Coding />}
              {i == 2 && <ML />}
              <StyledH1>{HeadingList1[i]}</StyledH1>
              <DescriptionWrapper> {DescriptionList[i]} </DescriptionWrapper>
            </FlexChild>
          </animated.div>
        ))}
      </FlexContainer>
    </OuterWrapper>
  );
};

export default WhatIDo;
