import { graphql, useStaticQuery } from "gatsby";

const BackgroundImageQuery = () => {
  const Image = useStaticQuery(
    graphql`
      query BackgroundImageQuery {
        file(relativePath: { eq: "png/content13-background.png" }) {
          childImageSharp {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
      }
    `
  );
  return Image.file.childImageSharp.fluid;
};

export default BackgroundImageQuery;
