import React, { useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "react-spring";
import { FooterWrapper, StyledParagraph } from "./styles";

const Footer = () => {
  const [ref, inView] = useInView({
    rootMargin: "-70px",
    triggerOnce: true
  });
  const props = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "translate(0px,0px)" : "translate(-10px,20px)",
    from: { opacity: 0, transform: "translate(-10px, 20px)" }
  });
  return (
    <FooterWrapper ref={ref}>
      <StyledParagraph>
        <animated.div style={props}>
          Made with ❤ and gatsby &emsp; &emsp; &emsp;&emsp; Thanks for visiting
        </animated.div>
      </StyledParagraph>
    </FooterWrapper>
  );
};

export default Footer;
