import React from "react";
import { ThemeContext } from "../../layouts";
import Seo from "../../components/Seo";
import Project from "../../components/Projects/Projects";

export default class Projects extends React.Component {
  render() {
    return (
      <React.Fragment>
        <ThemeContext.Consumer>{theme => <Project theme={theme} />}</ThemeContext.Consumer>
        <Seo />
      </React.Fragment>
    );
  }
}
